import { Input, Select, Table } from 'antd';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 0 5px !important;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .ant-table {
    color: black;
    table-layout: auto !important;
    // table-layout: fixed !important;
  }

  .ant-table-content {
    overflow-x: auto; 
  }
  
`;

export const StyledClientTable = styled(Table)`
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 0 0px !important;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .ant-table {
    color: black;
    font-size:16px;
    table-layout: auto !important;
  }

  .ant-table-content {
    overflow-x: auto; 
  }
`;

export const StyledStandUpTable = styled(Table)`
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 4px !important;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .ant-table {
    color: black;
    font-size:14px;
    table-layout: auto !important;
  }

  .ant-table-content {
    overflow-x: auto; 
  }
`;

export const StyledOffersTable = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 4px !important;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .ant-table {
    color: black;
    font-size:14px;
    table-layout: auto !important;
  }

  .ant-table-content {
    overflow-x: auto; 
  }
`;

export const StyledSelect = styled(Select)`
    .ant-select-selector {
        height: 24px;
        padding: 0 8px;
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    .ant-select-selection-item {
        line-height: 22px;
    }

    .ant-select-arrow {
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const StyledInput = styled(Input)`
  height: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;

  .ant-input {
  margin:0px
    height: 100%;
    line-height: 1.2;
    }
`;

export const StyledInfinityScrollTable = styled(Table)`
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 0 5px !important;
    // white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .ant-table {
    color: black;
    table-layout: auto !important;
    // table-layout: fixed !important;
  }

  .ant-table-content, .ant-table-header {
    overflow: auto !important; 
  }

  .gx-badge{
    white-space: normal;
  }
  
`;

export const StyledCalendarTable = styled(Table)`
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    // padding: 0 0px !important;
    // white-space: nowrap; /* Prevents text from wrapping */
  }
    .ant-table-cell {
    vertical-align: bottom; 
      height: 115px; /* Corrected typo from hight to height */
      }
      .ant-table-thead > tr > th {
        // font-weight: bold;
        vertical-align: bottom; 
        height: 100px;
        // color: blue; /* Example of changing text color */
        padding:7px 16px
      }
  
  .ant-table {
    // color: black;
    // table-layout: auto !important;
    // table-layout: fixed !important;
  }

  .ant-table-content {
    // overflow-x: auto; 
  }
`;