import React from "react";
import {Switch} from "react-router";

import {RoleRestrictedRoute, UserRestrictedRoute} from "../util/routeRestriction"
import Dashboard from "./dashboard";
import UserCalendar from "./calendar";
import Recruitment from "./recruitment";
import Marketing from "./marketing";
import ProjectCoordinator from './projectCoordination/index'
import Engineer from "./engineer"
import Employee from "./user";
import HumanResource from "./humanResource"
import Profile from "./profile";
import Onboarding from "./onboarding";
import TransferRequest from "./TransferRequest"

const App = ({match, user, location, dispatch}) => (
  <div className="gx-main-content-wrapper">
      <Switch>
        <UserRestrictedRoute path={`${match.url}:username/dashboard`} dispatch={dispatch} user={user}
                             username={user.username}
                             location={location} component={Dashboard}/>
       <UserRestrictedRoute path={`${match.url}:username/calendar`} dispatch={dispatch} user={user}
                             username={user.username}
                             location={location} component={UserCalendar}/>
        <UserRestrictedRoute path={`${match.url}:username/transfer-requests`} dispatch={dispatch} user={user}
                             username={user.username}
                             location={location} component={TransferRequest}/>
        <UserRestrictedRoute path={`${match.url}:username/profile`} user={user} username={user.username}
                             location={location} dispatch={dispatch}
                             component={Profile}/>
        <RoleRestrictedRoute path={`${match.url}:username/recruitment`}
                             dispatch={dispatch}
                             user={user} roles={['Recruiter', 'Admin']}
                             component={Recruitment}/>
        <RoleRestrictedRoute path={`${match.url}:username/marketing`}
                             dispatch={dispatch}
                             user={user} roles={['Marketer', 'Admin']}
                             component={Marketing}/>
        <RoleRestrictedRoute path={`${match.url}:username/project_coordination`}
                             dispatch={dispatch}
                             user={user} roles={['Project Coordination', 'Admin']}
                             component={ProjectCoordinator}/>
        <RoleRestrictedRoute path={`${match.url}:username/engineering`}
                             dispatch={dispatch}
                             user={user} roles={['Engineering', 'Admin']}
                             component={Engineer}/>
        <RoleRestrictedRoute path={`${match.url}:username/human_resource`}
                             dispatch={dispatch}
                             user={user} roles={['Human Resource', 'Admin']}
                             component={HumanResource}/>
        <RoleRestrictedRoute path={`${match.url}:username/employee`} dispatch={dispatch} user={user} roles={['Admin']}
                             component={Employee}/>

      </Switch>

  </div>
);

export default App;
