export const typeFilters = [
  {
    'id': 0,
    'handle': 'b',
    'title': 'Billing',
    'icon': 'star'
  },
  {
    'id': 1,
    'handle': 't',
    'title': 'Time-sheet',
    'icon': 'exclamation'
  },
  {
    'id': 2,
    'handle': 'r',
    'title': 'Recruiter',
    'icon': 'crm'
  },
  {
    'id': 3,
    'handle': 'o',
    'title': 'Other',
    'icon': 'chart-composed'
  }
];

export const clientTypeFilters = [
  {
    'id': 0,
    'handle': 'v',
    'title': 'Vendor',
    'icon': 'star'
  },
  {
    'id': 1,
    'handle': 'i',
    'title': 'I/P',
    'icon': 'exclamation'
  },
  {
    'id': 2,
    'handle': 'c',
    'title': 'Client',
    'icon': 'crm'
  }
];

export const statusFilters = [
  {
    'id': 0,
    'handle': 'n',
    'title': 'New',
    'icon': 'star'
  },
  {
    'id': 1,
    'handle': 'o',
    'title': 'On-bench',
    'icon': 'exclamation'
  },
  {
    'id': 2,
    'handle': 'm',
    'title': 'Marketing',
    'icon': 'crm'
  },
  {
    'id': 4,
    'handle': 'p',
    'title': 'On-project',
    'icon': 'chart-composed'
  },
  {
    'id': 3,
    'handle': 't',
    'title': 'In training',
    'icon': 'data-display'
  },
];

export const marketerStatusFilters = [
  {
    'id': 1,
    'handle': 'm',
    'title': 'Marketing',
    'icon': 'crm'
  },
  {
    'id': 2,
    'handle': 'p',
    'title': 'On-project',
    'icon': 'chart-composed'
  }
];

export const rtgFilters = [
  {
    'id': 0,
    'handle': 'True',
    'title': 'Yes',
    'icon': 'check-circle-o'
  },
  {
    'id': 1,
    'handle': 'False',
    'title': 'No',
    'icon': 'close-circle'
  }];


export const subStatusFilters = [
  {
    'id': 0,
    'handle': 'new',
    'title': 'New',
    'icon': 'star'
  },
  {
    'id': 1,
    'handle': 'sub',
    'title': 'Submitted',
    'icon': 'exclamation'
  },
  {
    'id': 3,
    'handle': 'int1,int2,fin_int',
    'title': 'Interview',
    'icon': 'crm'
  },
  {
    'id': 4,
    'handle': 'offer',
    'title': 'Offer',
    'icon': 'chart-composed'
  },
  {
    'id': 2,
    'handle': 'scr,scr_2',
    'title': 'Screening',
    'icon': 'data-display'
  },
];

  export const submissionStatusFilters = [
    {
      'id': 0,
      'handle': 'p',
      'title': 'Pending',
      'icon': 'star'
    },
    {
      'id': 1,
      'handle': 'c',
      'title': 'Completed',
      'icon': 'exclamation'
    },
    {
      'id': 3,
      'handle': 'f',
      'title': 'Failed',
      'icon': 'crm'
    },

  ]

  export const offersStatusFilters = [
    {
      'id': 0,
      'handle': 'all',
      'title': 'All',
      'icon': 'star',
      'color': '#1677ff'
    },
    // {
    //   'id': 1,
    //   'handle': 'p',
    //   'title': 'Pending',
    //   'icon': 'star',
    //   'color': '#1677ff'
    // },
    {
      'id': 5,
      'handle': 'j',
      'title': 'Joining Pending',
      'icon': 'crm',
      'color': '#1677ff'
    },
    {
      'id': 3,
      'handle': 'o',
      'title': 'Ongoing',
      'icon': 'crm',
      'color': '#52c41a'
    },
    {
      'id': 2,
      'handle': 'c',
      'title': 'Completed',
      'icon': 'exclamation',
      'color': '#52c41a'
    },
    {
      'id': 4,
      'handle': 't',
      'title': 'Terminated',
      'icon': 'crm',
      'color': '#ff4d4f'
    },
    {
      'id': 6,
      'handle': 'n',
      'title': 'Never Started',
      'icon': 'crm',
      'color': '#ff4d4f'
    },
  ]

  export const ProjectTypeFilters = [
    {
      'id': 0,
      'handle': 'all',
      'title': 'All',
    },
    {
      'id': 1,
      'handle': 'inhouse',
      'title': 'In-House',
    },
    {
      'id': 2,
      'handle': 'outsource',
      'title': 'Outsource',
    },
    {
      'id': 3,
      'handle': 'regular',
      'title': 'Regular',
    },
]

  export const PaginationPageLength = [
    {
      'id': 1,
      'title': "20 / List",
      'handle': 20,
    },
    {
      'id': 2,
      'title': "50 / List",
      'handle': 50,
    },
    {
      'id': 3,
      'title': "100 / List",
      'handle': 100,
    },
  ]

  export const PaymentFrequency = [
    {
      'id': 1,
      'title': "Weekly",
      'handle': 'weekly',
    },
    {
      'id': 2,
      'title': "Bi-Weekly",
      'handle': 'bi_weekly',
    },
    {
      'id': 3,
      'title': "Semi-Monthly",
      'handle': 'semi_monthly',
    },
    {
      'id': 4,
      'title': "Monthly",
      'handle': 'monthly',
    },
  ]
  export const CurrencyChoices = [
    {
      'id': 1,
      'title': "INR",
      'handle': 'inr',
    },
    {
      'id': 2,
      'title': "USD",
      'handle': 'usd',
    }
  ]
  export const SupplySourceChoices = [
    {
      'id': 1,
      'title': "Direct",
      'handle': 'direct',
    },
    {
      'id': 2,
      'title': "Supplier",
      'handle': 'supplier',
    }
  ]

  export const WeekDaysChoices = [
    {
      'id': 2,
      'title': "Monday",
      'handle': 1,
    },
    {
      'id': 3,
      'title': "Tuesday",
      'handle': 2,
    },
    {
      'id': 4,
      'title': "Wednesday",
      'handle': 3,
    },
    {
      'id': 5,
      'title': "Thrusday",
      'handle': 4,
    },
    {
      'id': 6,
      'title': "Friday",
      'handle': 5,
    },
    {
      'id': 7,
      'title': "Saturday",
      'handle': 6,
    },
    {
      'id': 8,
      'title': "Sunday",
      'handle': 0,
    },
  ]

  export const timeZoneOptions = [
    { value: "America/New_York", label: "EST" },
    { value: "America/Chicago", label: "CST" },
    { value: "America/Denver", label: "MST" },
    { value: "America/Los_Angeles", label: "PST" },
    { value: "Asia/Kolkata", label: "IST" },
  ];