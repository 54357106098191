// Auth Constants
export const GET_TOKEN  = 'auth/token/';
export const REFRESH_TOKEN = 'auth/token/refresh/';
export const CREATE_USER = 'auth/register/';
export const LOGOUT = 'auth/logout/';
export const USER_PASSWORD_UPDATE = 'auth/password/change/';
export const USER_UNLOCK_SCREEN = 'auth/login/';
export const RESET_PASSWORD =`auth/password-reset/request/`;

// User Constants
export const GET_USER = 'users/';
export const GET_PROFILE = 'profiles/me/';
export const USER_UPDATE = `users/username/`;
export const PROFILE_UPDATE = `profiles/username/`;
export const GET_EDUCATION_LIST = 'education/my_list/';
export const GET_EDUCATION = 'education/my/';
export const ADD_USER_EDUCATION = 'education/';
export const EDIT_USER_EDUCATION = 'education/id/';
export const GET_EXPERIENCE_LIST = 'experience/my_list/';
export const ADD_USER_EXPERIENCE = 'experience/';
export const EDIT_USER_EXPERIENCE = 'experience/id/';
export const GET_EXPERIENCE = 'experience/my/';
export const GET_PREVIOUS_CLIENT_LIST = 'consultants/id/previous-clients/';

export const GET_ADDRESS='addresses/id/';
export const GET_ADDRESS_LIST='addresses/id/';
export const ADD_USER_ADDRESS='addresses/';
export const EDIT_USER_ADDRESS='addresses/addId/id/';
export const DELETE_USER_ADDRESS='/addresses/delId/delete/id/'

export const GET_PRACTICE_LIST='consultants/id/practices/'
export const EDIT_USER_PRACTICE = 'consultants/id/practices/pracId/'
export const ADD_USER_PRACTICE = 'consultants/id/practices/'
export const GET_PRACTICE = 'consultants/id/practices/'

export const GET_SCREENING_CONSULTANTS = `consultants/screening-consultants/`;
// export const USER_UPDATE = 'users/username/';

//Consultant Constants
export const CREATE_CONSULTANT = 'consultants/';
export const GET_ALL_CONSULTANTS = 'consultants/my/';
export const EDIT_CONSULTANT = `consultants/slug/`;
export const EDIT_CONSULTANT_STATUS = `consultants/slug/update-status/`;
export const EDIT_CONSULTANT_COMMENTS = `consultants/slug/addcomments/`;
export const GET_CONSULTANT = `consultants/slug/`;
export const FETCH_MEMBERS = `consultants/slug/members/`;
export const FETCH_ATTACHMENTS = `consultants/slug/attachments/`;
export const FETCH_COMMENTS = `consultants/slug/comments/`;
export const SEARCH_CONSULTANT = `consultants/search/`;
export const SEARCH_EXISITING_CONSULTANT = `consultants/search-by-contact/`;
export const CREATE_TRANSFER_REQUEST = `consultants/transfer-requests/by-me/`;
export const GET_TRANSFER_REQUEST_FOR_ME = `consultants/transfer-requests/for-me/`;
export const GET_TRANSFER_REQUEST_BY_ME = `consultants/transfer-requests/by-me/`;
export const UPDATE_TRANSFER_REQUEST = `consultants/transfer-requests/update-status/`;
export const ASSIGN_LEAD = `consultants/assign-lead/`;
export const MARKETING_PROFILE = `marketing/profiles/`;
export const CONTINUE_MARKETING = `consultants/slug/update-marketing-status/`;
export const TEAM_MARKETING_PROFILE = `marketing/profiles/?team_profiles=true`
export const UPDATE_MARKETING_PROFILE = `marketing/profiles/profId/update/`;
export const GET_SEARCHED_CONSULTANT = "consultants/conId/search-details/";
// consultant onboarding and bgc forms
export const GET_MARKETING_CONSULTANTS = `consultants/marketing-consultants/`;
export const MARKETING_PROFILE_TRANSFER = `marketing/profiles/profId/transfer/`;

// consultant onboarding and bgc forms
export const CONSULTANT_ONBOARDING = `consultants/on-boarding/`;
export const CONSULTANT_BACKGROUND = `consultants/bgc-details/`;

// actions - bgc and onboarding
export const ASK_CONSULTANT_ONBOARDING = `consultants/con_slug/ask_onboarding_details/`;
export const ASK_CONSULTANT_BGC = `consultants/conSlug/ask_bgc_details/`;

//Recruiter Constants
export const GET_CONSULTANTS = `consultants/my/`;
export const GET_TEAM_CONSULTANTS = `consultants/my/?team_profiles=true`;
export const GET_RECRUITER_DASHBOARD_DATA = `employees/id/dashboard_data/`;
export const GET_SCREENING_RATIO = `employees/id/screenings_ratio/`;
export const GET_STATUS_RATIO = `consultants/status_ratio/`;
export const GET_ACTIVE_RATIO = `consultants/active_ratio/`;
export const GET_HIRED_RATIO = `consultants/hired_ratio/`;
export const GET_LEFT_RATIO = `consultants/left_ratio/`;
export const GET_RECENT_RECRUITS = `consultants/recent/`;
export const GET_RECENT_RECRUITS_BY_STATUS = `consultants/recruit_status/`;
export const GET_SCREENERS = `employees/id/screeners/`;
export const GET_SCHEDULED_SCREENINGS = `screenings/my/`;
export const UPDATE_SCREENING_STATUS = `screenings/id/`;
export const GET_EVENTS = `screenings/screener_calendar/`;
export const CREATE_EVENT = `screenings/create_event/`;
export const DELETE_EVENT = `screenings/delete_event/`;
export const GET_EMPLOYEE_CONSULTANTS = `consultants/recruiters/`;
export const BULK_REASSIGNMENT = `consultants/bulk_reassignment/`;
export const GET_RECRUITERS_ADDED_CONSULTANT_COUNT_LIST = `consultants/recruitment-data/`;

// ASSIGNA AND REMOVE MARKETER
export const ASSIGN_MARKETER = `consultants/slug/assign_marketer/`;
export const REMOVE_MARKETER = `consultants/remove-marketers/`;
export const REMOVE_CONSULTANT_FROM_MARKETER = `consultants/remove-consultants-from-marketers/`;




//Attachment Constants
export const ADD_ATTACHMENT = `attachments/`;
export const DELETE_ATTACHMENT = `attachments/id/`;
export const ATTACHMENT_STATUS = `attachments/id/update-status/`;

// Comment Constants
export const EDIT_COMMENT = `comments/id/`;

//Contact Constants
export const ADD_CONTACT = `contacts/`;
export const EDIT_CONTACT = `contacts/clientId/`;
export const GET_CONTACTS = `contacts/`;
export const GET_CLIENTS = `clients/`;
export const ADD_CLIENTS = `clients/`;
export const EDIT_CLIENTS = `clients/clientId/`;
export const ADD_POC = `contacts/`;
export const UPDATE_POC = `contacts/pocId/`;
export const REMOVE_DUPLICATE = `clients/remove-duplicate/`;

//Submission Constants
export const ADD_SUBMISSION = `submissions/`;
export const GET_SUBMISSION = `submissions/id/`;
export const EDIT_SUBMISSION = `submissions/id/`;
export const GET_MY_SUBMISSIONS = `submissions/my/`;
export const GET_PENDING_SUBMISSIONS = `submissions/pending/`;
export const GET_TEAM_SUBMISSIONS = `submissions/my/?team_profiles=true`;
export const FETCH_SUBMISSION_ATTACHMENTS = `submissions/id/attachments/`;
export const GET_ALL_SUBMISSIONS = `submissions/my/all-submissions/`;
export const DELETE_SUBMISSION = `submissions/submissionId/`;
// assessments constants
export const GET_ASSESSMENTS_LIST = `assessments/`;
export const GET_TEAM_ASSESSMENTS = `assessments/?team_profiles=true`
export const GET_ASSESSMENTS = `submissions/submissionId/assessments/`;
export const CREATE_ASSESSMENT_EVENT = `assessments/`;
export const EDIT_ASSESSMENTS = `assessments/assessmentId/`;
export const DELETE_ASSESSMENTS = `assessments/assessmentId/`;


// offer constants
export const GET_OFFERS = `offers/`;
export const GET_TEAM_OFFERS = `offers/?team_profiles=true`;
export const CREATE_OFFER = `offers/`;
export const DELETE_OFFER = `offers/offerId/delete/`;
export const EDIT_OFFERS = `offers/offerId/`

// project Coordinator
export const GET_PROJECT_COORDINATORS_LIST = `departments/employees/?department=project_coordination`;
export const GET_PROJECT_COORDINATORS_OFFERS = `project-coordinators/offers/`;
export const EDIT_PROJECT_COORDINATORS_OFFERS = `project-coordinators/offers/id/`;
export const GET_SUPPORT_PERSON_LIST = `offers/support-persons/`;
export const ADD_SUPPORT_PERSON = `offers/support-persons/`;
export const EDIT_SUPPORT_PERSON = `offers/support-persons/id/`;

//engineer 
export const GET_ENGINEER_OFFERS = `offers/inhouse/`;
export const Edit_ENGINEER_OFFERS = `offers/inhouse/id/`;
export const GET_TIMESHEET_CONFIG_LIST = `timesheets/timesheet-config/?offer=`;
export const CREATE_TIMESHEET_CONFIG = `timesheets/timesheet-config/`;
export const ADD_TIMESHEET_HOURS = `timesheets/timesheet/`;
export const ADD_TIMESHEET_ATTACHMENT = `timesheets/timesheet-attachments/`;
export const EDIT_TIMESHEET_HOURS = `timesheets/timesheet/`;
export const GET_TIMESHEET_HOURS_LIST = `timesheets/timesheet/`;
export const GET_TIMESHEET_ATTACHMENT_LIST = `timesheets/timesheet-attachments/`;

//Employee Constants
export const ALL_EMPLOYEES = `departments/employees/`;
export const CREATE_EMPLOYEE = `employees/`;
export const GET_EMPLOYEE = `employees/id/`;
export const EDIT_EMPLOYEE = `employees/id/`;
export const GET_MANAGERS = `employees/managers/`;
export const GET_MARKETERS = `employees/marketers/`;
export const GET_EMPLOYERS = `employees/employers/`;
export const GET_DEPARTMENTS = `departments/`;
export const GET_LEVELS = `employees/levels/`;
export const FETCH_TASKS = `tasks/`;
export const GET_MARKETING_LEAD = `employees/leads/?department=marketer`;
export const GET_RECRUITMENT_LEAD = `employees/leads/?department=recruitment`;
export const EMPLOYEE_LIST = `employees/employees_list/`;
export const GET_MARKETERS_SUBORDINATES = `employees/marketer_subordinates/`;
export const GET_SUPPORT_ID_LIST = `departments/employees/?department=engineering`;

// standup constants
export const GET_MARKETING_STANDUP_LIST = `analytics/marketing-standup/`;

// analytics constants
export const GET_OFFER_RATIO = `analytics/monthwise-total-offers/`;
export const GET_MARKETERS_ANALYTICS = `analytics/marketer-analytics/`;
export const GET_MARKETING_STATUS_COUNT = `analytics/status-marketing/`;
export const GET_ASSIGNED_CONSULTANT_DATA = `analytics/assigned-consultant-data/`;
export const GET_MARKETER_PNL_OFFERS = `analytics/marketing-profile-p&l/`;
export const GET_ONGOING_MARKETING_OFFERS =`analytics/total-ongoing-offers/`;
export const GET_CONSULTANT_COUNT_OF_RECRUITER = `analytics/recruiter-consultants/`;
export const GET_PROJECT_TYPE_TABLE_DATA = `analytics/profit-loss-by-project-type/`;
export const GET_TOTAL_MARKETING_PROFILE_TABLE_DATA = `analytics/marketing-profiles-by-technologies/`;
export const GET_ASSIGNED_CONSULTANT_BY_GROUP_TABLE_DATA = `analytics/assigned-consultants-and-profiles/`;
export const GET_MONTHWISE_SUBMISSION_AND_ASSESSMENT_DATA = `analytics/monthwise-submissions-and-assessments/`;
export const GET_CURRENT_WEEK_OFFERS_LIST = `analytics/current-week-offers/`;

//Static Data Constants

export const VISA_STATUS = 'visa/';
export const SOURCE_OPTIONS = 'source/';

// Notifications 
export const GET_NOTIFICATIONS = 'notifications/received/?page=pageNumber';
export const READ_NOTIFICATIONS = 'notifications/mark-read/';

// Transfer Requests Status Change

export const TRANSFER_REQUEST = 'consultants/transfer-requests/update-status/';

// Approve/Reject Practices

export const PRACTICES_STATUS = 'consultants/consId/practices/pracId/update-status/';