
import {fetchError, fetchStart,handleErrorResponse, fetchSuccess, showMessage, handleRemoveDisableSubmitButton} from "./Common";
import {userService} from "../services";
import { ADD_SUPPORT_PERSON, ADD_TIMESHEET_ATTACHMENT, ADD_TIMESHEET_HOURS, CREATE_OFFER, CREATE_TIMESHEET_CONFIG, DELETE_OFFER, EDIT_OFFERS, EDIT_PROJECT_COORDINATORS_OFFERS, EDIT_SUPPORT_PERSON, EDIT_TIMESHEET_HOURS, Edit_ENGINEER_OFFERS, GET_ENGINEER_OFFERS, GET_OFFERS, GET_PROJECT_COORDINATORS_LIST, GET_PROJECT_COORDINATORS_OFFERS, GET_SUPPORT_PERSON_LIST, GET_TEAM_OFFERS, GET_TIMESHEET_ATTACHMENT_LIST, GET_TIMESHEET_CONFIG_LIST, GET_TIMESHEET_HOURS_LIST } from "../../constants/ApiEndpoints";
import { ADD_OFFER_FAILED, ADD_OFFER_SUCCESS, DELETE_OFFER_FAILURE, DELETE_OFFER_SUCCESS, EDIT_OFFER_FAILED, EDIT_OFFER_SUCCESS, FETCH_ENGINEER_JOINING_PENDING_OFFER_SUCCESS, FETCH_ENGINEER_OFFER_FAILED, FETCH_ENGINEER_OFFER_LIST_SUCCESS, FETCH_MONTHLY_OFFER_SUCCESS, FETCH_OFFER_FAILED, FETCH_OFFER_SUCCESS, FETCH_PROJECT_COORDINATORS_LIST_SUCCESS, FETCH_PROJECT_COORDINATORS_NO_PAGINATION_LIST_SUCCESS, FETCH_PROJECT_COORDINATORS_OFFER_FAILED, FETCH_PROJECT_COORDINATORS_OFFER_SUCCESS, FETCH_PROJECT_COORDINATORS_ONGOING_OFFER_SUCCESS, FETCH_SUPPORT_PERSON_LIST_SUCCESS, FETCH_SUPPORT_PERSON_LIST_WITH_NO_PAGINATION_SUCCESS, FETCH_TEAM_OFFER_FAILED, FETCH_TEAM_OFFER_SUCCESS, FETCH_TIMESHEET_ATTACHMENT_LIST_SUCCESS, FETCH_TIMESHEET_CONFIG_LIST_SUCCESS, FETCH_TIMESHEET_HOURS_LIST_SUCCESS } from "../../constants/ActionTypes";
import { handleErrorsInDifferentKey } from "../../util/ErrorFormating/errorUtils";
import { defaultPageListLimit } from "../../constants/Constants";

export const addOfferSuccess = (offer) => {
  return {
    type: ADD_OFFER_SUCCESS,
    payload: offer
  }
};

export const addOfferFailed = () => {
  return {
    type: ADD_OFFER_FAILED,
  }
};

export const addOffer = (values) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(CREATE_OFFER, values).then((response) => {
      if(response) {
        dispatch(handleRemoveDisableSubmitButton('add_new_offer'))
        dispatch(addOfferSuccess(response));
        dispatch(handleErrorResponse({"addOffer": "success"}));
        dispatch(fetchOffers());
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('add_new_offer'))
      dispatch(handleErrorResponse({"addOffer": error}));
      dispatch(fetchError(error?error.data?error.data.error:"Error Adding Offer!":"Error Adding Offer!"));
      dispatch(addOfferFailed());
    })
  }
};


export const fetchOffersSuccess = (offers) => {
  return {
    type: FETCH_OFFER_SUCCESS,
    payload: offers
  }
};

export const fetchOffersFailure = () => {
  return {
    type: FETCH_OFFER_FAILED,
  }
};


export const fetchOffers = (filterBy={page:1 ,marketer:null ,project_start_date: null ,project_end_date: null,
    team_profiles:null ,from_date: null ,to_date: null ,include_self:false ,q:null ,page_size:null}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_OFFERS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchOffersSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchOffersFailure());
    });
  };
};

// for the marketerDashboard table "offers for the month table"

export const fetchMonthlyOffersSuccess = (offers) => {
  return {
    type: FETCH_MONTHLY_OFFER_SUCCESS,
    payload: offers
  }
};

export const fetchMonthlyOffers = (filterBy={page:1 ,marketer:null ,project_start_date: null ,project_end_date: null ,team_profiles:null ,from_date: null ,to_date: null ,include_self:false}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_OFFERS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchMonthlyOffersSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchOffersFailure());
    });
  };
};


// TEAM OFFER
export const fetchTeamOffersSuccess = (offers) => {
  return {
    type: FETCH_TEAM_OFFER_SUCCESS,
    payload: offers
  }
};

export const fetchTeamOffersFailure = () => {
  return {
    type: FETCH_TEAM_OFFER_FAILED,
  }
};


export const fetchTeamOffers = (filterBy={page:1 ,q:null}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_TEAM_OFFERS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchTeamOffersSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchTeamOffersFailure());
    });
  };
};

export const deleteOfferSuccess =  () => {
  return{
    type: DELETE_OFFER_SUCCESS,
  }
};

export const deleteOfferFailure =  () => {
  return {
    type: DELETE_OFFER_FAILURE,
  }
};

export const deleteOffer = (offerId ,filterBy={remark : null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const endPoint = DELETE_OFFER.replace('offerId', offerId);
    userService.patch(endPoint ,filterBy).then((response) => {
        dispatch(deleteOfferSuccess());
        dispatch(fetchOffers());
        dispatch(fetchSuccess());
        dispatch(showMessage("Offer Deleted Successfully!"));
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      if(error && error.data && error.data.errors){
        dispatch(fetchError(error.data.errors));
      }
      dispatch(deleteOfferFailure());
    });
  }
};


export const editOfferSuccess = (offers) => {
  return {
    type: EDIT_OFFER_SUCCESS,
    payload: offers
  }
};

export const editOfferFailure = () => {
  return {
    type: EDIT_OFFER_FAILED,
  }
};

export const editOffer = (offerId=null, values=null ,filterBy={updateTableCellOnResponse:()=>{},fetchListOnResponce:true}) => {  // fetchListOnResponce this is passed to call the list again after the response or not 
  return(dispatch) => {
  dispatch(fetchStart());
  const apiEndpoint = EDIT_OFFERS.replace('offerId', offerId);
  userService.patch(apiEndpoint, values).then((response) => {
      if (response) {
        filterBy.updateTableCellOnResponse()
        dispatch(handleRemoveDisableSubmitButton('edit_offers_form'))
        dispatch(fetchSuccess());
        dispatch(editOfferSuccess(response));
        (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchOffers()) 
        dispatch(handleErrorResponse({"editOffer": "success"}));
        dispatch(showMessage("Offer Edited Successfully"))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(handleRemoveDisableSubmitButton('edit_offers_form'))
      handleErrorsInDifferentKey(error)
      dispatch(handleErrorResponse({"editOffer": error}));
      (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchOffers()) 
      dispatch(editOfferFailure());
    });
  };
};

// project coordinator

export const fetchProjectCoordinatorsOffersListSuccess = (offers) => {
  return {
    type: FETCH_PROJECT_COORDINATORS_OFFER_SUCCESS,
    payload: offers
  }
};
export const fetchProjectCoordinatorsOngoingOffersListSuccess = (offers) => {
  return {
    type: FETCH_PROJECT_COORDINATORS_ONGOING_OFFER_SUCCESS,
    payload: offers
  }
};


export const fetchProjectCoordinatorsOffersListFailure = () => {
  return {
    type: FETCH_PROJECT_COORDINATORS_OFFER_FAILED,
  }
};


export const fetchProjectCoordinatorsOffersList = (filterBy={page:1 ,marketer:null ,project_start_date: null ,project_end_date: null,
  team_profiles:null ,from_date: null ,to_date: null  ,q:null ,page_size:null , status:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_PROJECT_COORDINATORS_OFFERS, filterBy).then((response) => {
        if (response) {
          if(filterBy.status==='j'){
            dispatch(fetchProjectCoordinatorsOffersListSuccess(response));
          }
          else{
            dispatch(fetchProjectCoordinatorsOngoingOffersListSuccess(response))
          }
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
        dispatch(fetchProjectCoordinatorsOffersListFailure())
      });
  };
};


export const editProjectCoordinatorsOffers = (offerId=null, values=null ,filterBy={updateTableCellOnResponse:()=>{},fetchListOnResponce:true, 
    status:null ,closeModelOnResponce:()=>{}}) => {  // fetchListOnResponce this is passed to call the list again after the response or not 
  return(dispatch) => {
  dispatch(fetchStart());
  const apiEndpoint = EDIT_PROJECT_COORDINATORS_OFFERS.replace('id', offerId);
  userService.patch(apiEndpoint, values,{
    'Content-Type': 'multipart/form-data',
    }).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        filterBy.closeModelOnResponce() // this the function to close the model from which he api is called
        // filterBy.updateTableCellOnResponse() // this will be used when editing the table data from the table
        if(filterBy.status==='j'){
          (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchProjectCoordinatorsOffersList({status:'j'}))
          if(values !== null && values.get('project_status') === 'o'){
            dispatch(fetchProjectCoordinatorsOffersList({status:'o'}))
          } 
        }
        else{
          (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchProjectCoordinatorsOffersList({status:'o'})) 
        }
        dispatch(handleRemoveDisableSubmitButton('edit_project_coordinators_offers_form'))
        dispatch(showMessage("Offer Edited Successfully"))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      // handleErrorsInDifferentKey(error)
      dispatch(handleRemoveDisableSubmitButton('edit_project_coordinators_offers_form'))
    });
  };
};

export const fetchSupportPersonsListSuccess = (response) => {
  return {
    type: FETCH_SUPPORT_PERSON_LIST_SUCCESS,
    payload: response
  }
};

export const fetchSupportPersonsListWithNoPaginationSuccess = (response) => {
  return {
    type: FETCH_SUPPORT_PERSON_LIST_WITH_NO_PAGINATION_SUCCESS,
    payload: response
  }
};

export const fetchSupportPersonsList = (filterBy={pagination:true,page:1 ,pageSize: defaultPageListLimit}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_SUPPORT_PERSON_LIST,filterBy).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
          if(filterBy.pagination === false){
            dispatch(fetchSupportPersonsListWithNoPaginationSuccess(response))
          }else{
            dispatch(fetchSupportPersonsListSuccess(response))
          }
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const addSupportPersonSuccess = (offer) => {
  return {
    type: ADD_OFFER_SUCCESS,
    payload: offer
  }
};

export const addSupportPerson = (values, filterBy={ closeModelOnResponse:()=>{} ,pageListToFetchOnResponce:1,
  currentFilters:{}, user:[], pageSize: defaultPageListLimit}) =>{
  return (dispatch) => {
    const filters = filterBy && filterBy.currentFilters
    dispatch(fetchStart());
    userService.post(ADD_SUPPORT_PERSON, values,{
      'Content-Type': 'multipart/form-data',
      }).then((response) => {
      if(response) {
        dispatch(fetchSupportPersonsList({pagination: true ,page: filterBy.pageListToFetchOnResponce, 
          supply_source: filters.supply_source ,name: filters.name ,rate: filters.rate , technology: filters.technology, 
          frequency_of_payment: filters.frequency_of_payment, currency: filters.currency,
          experience: filters.experience 
          ,...( filterBy.user && filterBy.user.groups.includes("Admin") && {page_size: filterBy.pageSize})
        })) 
        dispatch(handleRemoveDisableSubmitButton('add_support_person_form'))
        dispatch(showMessage("Added Successfully!"));
        if(typeof filterBy.closeModelOnResponse === 'function'){
          filterBy.closeModelOnResponse();
        }
        dispatch(handleErrorResponse({"add_support_person": "success"}));
        dispatch(fetchSuccess());
      } 
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('add_support_person_form'))
      dispatch(handleErrorResponse({"add_support_person": error}));
      dispatch(fetchError(error?error.data?error.data.error:"Error Adding Support Person!":"Error Adding  Support Person!"));
    })
  }
};

export const editSupportPerson = (recordId=null, values=null ,filterBy={updateTableCellOnResponse:()=>{},fetchListOnResponce:true,
closeModelOnResponse:()=>{} ,pageListToFetchOnResponce:1 ,currentFilters:{}, user:[] ,pageSize: defaultPageListLimit}) => {  // fetchListOnResponce this is passed to call the list again after the response or not 
  return(dispatch) => {
  const filters = filterBy && filterBy.currentFilters
  dispatch(fetchStart());
  const apiEndpoint = EDIT_SUPPORT_PERSON.replace('id', recordId);
  userService.patch(apiEndpoint, values,{
    'Content-Type': 'multipart/form-data',
    }).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        if(typeof filterBy.closeModelOnResponse === 'function'){
          filterBy.closeModelOnResponse();
        }
        (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && 
              dispatch(fetchSupportPersonsList({pagination: true ,page: filterBy.pageListToFetchOnResponce, 
                supply_source: filters.supply_source ,name: filters.name ,rate: filters.rate , technology: filters.technology, 
                frequency_of_payment: filters.frequency_of_payment, currency: filters.currency,
                experience: filters.experience
                ,...( filterBy.user && filterBy.user.groups.includes("Admin") && {page_size: filterBy.pageSize})
              })) 

        dispatch(handleErrorResponse({"add_support_person": "success"}));
        dispatch(handleRemoveDisableSubmitButton('add_support_person_form'))
        dispatch(showMessage("Support person edited successfully!"))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      // handleErrorsInDifferentKey(error)
      dispatch(handleErrorResponse({"add_support_person": error}));
      dispatch(handleRemoveDisableSubmitButton('add_support_person_form'))
    });
  };
};

export const fetchProjectCoordinatorsListSuccess = (response) => {
  return {
    type: FETCH_PROJECT_COORDINATORS_LIST_SUCCESS,
    payload: response
  }
};

export const fetchProjectCoordinatorsNoPaginationListSuccess = (response) => {
  return {
    type: FETCH_PROJECT_COORDINATORS_NO_PAGINATION_LIST_SUCCESS,
    payload: response
  }
};

export const fetchProjectCoordinatorsList = (filter_By={ all:false}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_PROJECT_COORDINATORS_LIST,filter_By).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
          if(filter_By && filter_By.all === true){
            dispatch(fetchProjectCoordinatorsNoPaginationListSuccess(response))
          }else{
            dispatch(fetchProjectCoordinatorsListSuccess(response))
          }
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
     });
  };
};

// engineer 
export const fetchEngineeJoiningPendingOffersListSuccess = (offers) => {
  return {
    type: FETCH_ENGINEER_JOINING_PENDING_OFFER_SUCCESS,
    payload: offers
  }
};
export const fetchEngineeOffersListSuccess = (offers) => {
  return {
    type: FETCH_ENGINEER_OFFER_LIST_SUCCESS,
    payload: offers
  }
};


export const fetchEngineeOffersListFailure = () => {
  return {
    type: FETCH_ENGINEER_OFFER_FAILED,
  }
};


export const fetchEngineerOffersList = (filterBy={page:1 ,marketer:null ,project_start_date: null ,project_end_date: null,
  team_profiles:null ,from_date: null ,to_date: null  ,q:null ,page_size:null , project_status:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_ENGINEER_OFFERS, filterBy).then((response) => {
        if (response) {
          if(filterBy.project_status==='j'){
            dispatch(fetchEngineeJoiningPendingOffersListSuccess(response));
          }
          else{
            dispatch(fetchEngineeOffersListSuccess(response))
          }
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
          dispatch(fetchEngineeOffersListFailure())
      });
  };
};

export const editEngineerOffers = (offerId=null, values=null ,filterBy={updateTableCellOnResponse:()=>{},fetchListOnResponce:true, 
    status:null ,closeModelOnResponce:()=>{}}) => {  // fetchListOnResponce this is passed to call the list again after the response or not 
  return(dispatch) => {
  dispatch(fetchStart());
  const apiEndpoint = Edit_ENGINEER_OFFERS.replace('id', offerId);
  userService.patch(apiEndpoint, values,{
    'Content-Type': 'multipart/form-data',
    }).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        // filterBy.closeModelOnResponce() // this the function to close the model from which he api is called
        // filterBy.updateTableCellOnResponse() // this will be used when editing the table data from the table
        if(filterBy.status==='j'){
          (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchEngineerOffersList({project_status:'j'}))
          if(values !== null && values.get('project_status') === 'o'){
            dispatch(fetchEngineerOffersList({project_status:'o'}))
          } 
        }
        else{
          (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchEngineerOffersList({project_status:'o'})) 
        }
        dispatch(handleErrorResponse({"edit_engineer_offers_form": "success"}));
        dispatch(handleRemoveDisableSubmitButton('edit_engineer_offers_form'))
        dispatch(showMessage("Offer Edited Successfully"))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(handleErrorResponse({"edit_engineer_offers_form": error}));
      // handleErrorsInDifferentKey(error)
      dispatch(handleRemoveDisableSubmitButton('edit_engineer_offers_form'))
    });
  };
};

export const fetchTimeSheetConfigListSuccess = (response) => {
  return {
    type: FETCH_TIMESHEET_CONFIG_LIST_SUCCESS,
    payload: response
  }
};

export const fetchTimeSheetConfigList = (filterBy={offerId:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_TIMESHEET_CONFIG_LIST.concat(filterBy.offerId);
    userService.get(apiEndpoint).then((response) => {
        if (response) {
            dispatch(fetchTimeSheetConfigListSuccess(response))
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const createTimeSheetConfig = (values, filterBy={offerId:null}) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(CREATE_TIMESHEET_CONFIG, values).then((response) => {
      if(response) {
        // dispatch(handleRemoveDisableSubmitButton('add_new_offer'))
        // dispatch(handleErrorResponse({"addOffer": "success"}));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      // dispatch(handleRemoveDisableSubmitButton('add_new_offer'))
      // dispatch(handleErrorResponse({"addOffer": error}));
      dispatch(fetchError(error?error.data?error.data.error:"Error while updating config!":"Error while updating config!"));
      dispatch(addOfferFailed());
    })
  }
};

export const fetchTimeSheetHoursListSuccess = (response) => {
  return {
    type: FETCH_TIMESHEET_HOURS_LIST_SUCCESS,
    payload: response
  }
};

export const fetchTimeSheetHoursList = (filterBy={offer:null, from_date:null, to_date:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_TIMESHEET_HOURS_LIST,filterBy).then((response) => {
        if (response) {
            dispatch(fetchTimeSheetHoursListSuccess(response))
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const addTimeSheetHours = (filterBy={value:null}, updateTableCellOnResponse) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(ADD_TIMESHEET_HOURS, filterBy.value).then((response) => {
      if(response) {
        updateTableCellOnResponse()
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error?error.data?error.data.error:"Error while adding hours!":"Error while adding hours!"));
      dispatch(addOfferFailed());
    })
  }
};

export const editTimeSheetHours = (filterBy={value:null}, updateTableCellOnResponse) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(EDIT_TIMESHEET_HOURS, filterBy.value).then((response) => {
      if(response) {
        if(response && response.hours){
          updateTableCellOnResponse(response.hours)
        }
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error?error.data?error.data.error:"Error while updating hours!":"Error while updating hours!"));
      dispatch(addOfferFailed());
    })
  }
};

export const addTimeSheetAttachment = (filterBy={formData:null}) =>{
  const  headers = {
    'Content-Type': 'multipart/form-data'
  }
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(ADD_TIMESHEET_ATTACHMENT, filterBy.formData, headers).then((response) => {
      if(response) {
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error?error.data?error.data.error:"Error while adding attachment!":"Error while adding attachment!"));
      dispatch(addOfferFailed());
    })
  }
};

export const fetchTimeSheetAttachmentListSuccess = (response) => {
  return {
    type: FETCH_TIMESHEET_ATTACHMENT_LIST_SUCCESS,
    payload: response
  }
};


export const fetchTimeSheetAttachmentList = (filterBy={offer:null, from_date:null, to_date:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_TIMESHEET_ATTACHMENT_LIST,filterBy).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
            dispatch(fetchTimeSheetAttachmentListSuccess(response))
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};
