import React from "react";
import {Redirect, Switch} from "react-router";
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

const AsyncProjectCoordinatorEdit = asyncComponent(() => import('./projectCoordinators/edit'));
const AsyncProjectCoordinators = asyncComponent(() => import('./projectCoordinators/index'));
const AsyncProjectCoordinatorSupportPerson = asyncComponent(() => import('./supportPerson/index'));

const ProjectCoordinator = (props) => (
  <Switch>
    <Redirect exact from={`${props.match.url}/project_coordination`} to={`${props.match.url}`}/>
      <PropsRoute path={`${props.match.url}/support_person`} dispatch={props.dispatch}
        component={AsyncProjectCoordinatorSupportPerson} user={props.user}/>
      <PropsRoute path={`${props.match.url}/project_coordinators/edit/:project_coordinatorsId`} 
        dispatch={props.dispatch} component={AsyncProjectCoordinatorEdit} user={props.user}/>
      <PropsRoute path={`${props.match.url}/project_coordinators`} dispatch={props.dispatch}
        component={AsyncProjectCoordinators} user={props.user}/>
  </Switch>
);

export default ProjectCoordinator;
