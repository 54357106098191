import React, {useEffect, useState } from 'react'
import {editOffer, fetchSupportIdList, handleDisableSubmitButton, fetchProjectCoordinatorsList} from '../../appRedux/actions';
import {Col, Form, Input, Modal, Select, DatePicker, Checkbox} from "antd";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { groups, levelsConst } from '../../constants/Constants';
import { offersStatusFilters } from '../../constants/FilterData';
import { status } from 'nprogress';
import { EmployerCompanyOptions } from '../../constants/ConstantOptions';
const EditOfferForm = ({visible, setVisible,  dispatch, user, submissions=[], title, record, showSelectSubmission=true}) => {
    const loggedInUser = useSelector(({user}) => user.loggedInUser);
    const [currentEmployerCompany, setCurrentEmployerCompany] = useState("");
    const [form] = Form.useForm();
    const {TextArea} = Input;
    const {Option} = Select;
    const [currentProject, setCurrentProject] = useState(null);
    const errorForm = useSelector(({common}) => common.error_response);
    const supportIdList = useSelector(({employees}) => employees.supportIdList);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const disableSubmitButton = useSelector(({common}) => common.disableSubmitButton);
    const { projectCoordinatorsNoPaginationList } = useSelector(({offer})=>offer);

    useEffect(()=>{
      if(record && record.employer_company ==='Other') {
        setCurrentEmployerCompany('Other')
      }else{
        setCurrentEmployerCompany('')
      }
    },[record])

    useEffect(()=>{
      if(!projectCoordinatorsNoPaginationList){
        dispatch(fetchProjectCoordinatorsList({all:true}));
      }
    },[])

    const handleCancel = () => {
        // dispatch(hideModal());
        setVisible(false);
        form.resetFields();
      };
      
      function formatDateToYYYYMMDD(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const projectTypeMapping = {
        '1099-Business': '1099-b',
        '1099-Individual': '1099-i',
        'In-House': 'inhouse',
        Outsource: 'outsource',
        C2C: 'c2c',
        W2: 'w2',
      };

      useEffect(() => {
        if (record && record.employer_company && record.employer_company !== "Other" && EmployerCompanyOptions) {
          const filterTheDataInrecord = EmployerCompanyOptions.some(
            element => element.handle === record.employer_company
          );
          if (!filterTheDataInrecord) {
            setCurrentEmployerCompany("Other");
            form.setFields([
              { name: 'other_employer_company_name', value: record.employer_company },
              { name: 'employer_company', value: "Other" }
            ]);
          }
          else{
            setCurrentEmployerCompany('');
            form.resetFields(['other_employer_company_name'])
            form.setFields([
              { name: 'employer_company', value: record.employer_company }
            ]);
          }
        }
      }, [record, EmployerCompanyOptions, setCurrentEmployerCompany, form]);
    

    useEffect(() => {
        if (record && supportIdList) {
            const mappedProjectType = projectTypeMapping[record.project_type] || record.project_type;
          if(mappedProjectType === "inhouse"){
            setCurrentProject("inhouse");
          }
          else{
            setCurrentProject(null)
          }
          const initialSupport = supportIdList.find(item => item.user_id === record.inhouse_engineer);
          const initialSupportId = initialSupport ? initialSupport.user_id : null

          form.setFieldsValue({
            submission: `${record.consultant ? record.consultant : ''} :: ${record.vendor ? record.vendor : ''} :: ${record.client ? record.client : ''} :: ${record.job_title ? record.job_title : ''}` ,
            project_rate: record.project_rate,
            offered_date: moment(record.offered_date, "YYYY-MM-DD"), // Use moment to parse and format the date
            project_start_date: record.project_start_date?moment(record.project_start_date, "YYYY-MM-DD"): null,
            project_end_date: record.project_end_date?moment(record.project_end_date, "YYYY-MM-DD"):null,
            project_type: mappedProjectType,
            project_status: record.project_status,
            inhouse_engineer: initialSupportId,
            device_handling_rate: record.device_handling_rate,
            project_coordinator: record && record.project_coordinator,
            // employer_company : record && record.employer_company ? record.employer_company:null,
            // other_employer_company_name: record && record.other_employer_company_name?record.other_employer_company_name:null,
            remark: record.remark,
            payroll: record.payroll,
            payroll_cost: record.payroll_cost,
            support_rate: record.support_rate,
            payroll_tax_type:record.payroll_tax_type,
            consultant_rate: record.consultant_rate,
            tax_term: record.tax_term,
            technologies: record.technologies ? (typeof record.technologies === 'string' ? JSON.parse(record.technologies) : record.technologies.join(", ")) :"",
          });
    
          // Handle project type change if needed
          if (record.project_type === "inhouse") {
            setCurrentProject("inhouse");
          }
        }
      }, [record, visible]);
  
      useEffect(() => {
        if(errorForm && errorForm.editOffer && errorForm.editOffer =='success'){
          handleCancel();
        }
        else if(errorForm && errorForm.editOffer  && errorForm.editOffer.data && errorForm.editOffer.data.errors) {
           const errorFields = Object.keys(errorForm.editOffer.data.errors).map(key => ({
                name: key,
                errors: errorForm.editOffer.data.errors[key],
            }));
            form.setFields(errorFields);
        }
      }, [errorForm]);

    const [ checkedOutBox ,setCheckOutBox] = useState(false)

      const handleCheckBox = (e) => {
        setCheckOutBox(e.target.checked)
   }

  const onCreate = values => {
        if (typeof values.technologies === "string") {
          values['technologies'] = values.technologies?values.technologies.split(",").map((tech)=>{return tech.trim()}):[];
        }
        for (let key in values) {
          if (values[key] == undefined || values[key] =='undefined' || values[key] == "" ||values[key] == " ") {
            delete values[key];
          }
        }
        if (values.hasOwnProperty('offered_date')) {
            values.offered_date = formatDateToYYYYMMDD(values.offered_date);
        }
        if (values.hasOwnProperty('project_start_date')) {
            values.project_start_date = formatDateToYYYYMMDD(values.project_start_date);
        }else{
          values.project_start_date = null;
        }
        if (values.hasOwnProperty('project_end_date')) {
            values.project_end_date = formatDateToYYYYMMDD(values.project_end_date);
        }else{
          values.project_end_date = null
        }
        if(title === "Approve Offer"){
          if(checkedOutBox){
            values.project_start_date_acknowledged = checkedOutBox
          }
          else{
            values.project_start_date_acknowledged = false
          }
        }
        if(values.project_status.length !== 1){
            values.project_status = values.project_status[0].toLowerCase()
        } 
        if(values.project_type){
         values.project_type = values.project_type.replace(/^\w+/g, match => match.toLowerCase())
        }
        if(values['employer_company'] === "Other" ){
          values['employer_company'] = values['other_employer_company_name']
          delete values['other_employer_company_name']
        }
        dispatch(editOffer(record.id, values));
        dispatch(handleDisableSubmitButton('edit_offers_form',true))
        setCheckOutBox(false)
      };
    const handleProjectChange = (value)=>{
        setCurrentProject(value)
    }
     
    useEffect(()=>{
      setCheckOutBox(false)
      if(!supportIdList){
        dispatch( fetchSupportIdList({all:true}))
      }
    },[])
     
    const handleEmployerCompanyChange = (value)=>{
      setCurrentEmployerCompany(value);
    }
     
  useEffect(()=>{
    if(disableSubmitButton && disableSubmitButton.edit_offers_form && disableSubmitButton.edit_offers_form !==undefined ){
      setIsButtonDisabled(disableSubmitButton.edit_offers_form)
    }
    else{
      setIsButtonDisabled(false)
    }
  },[disableSubmitButton])

  return (
    <Modal
    key={'editModal'}
    open={visible}
    title={title}
    okText="Submit"
    width={700}
    cancelText="Cancel"
    onCancel={() => handleCancel()} 
    okButtonProps={{ disabled: isButtonDisabled }}
    onOk={() => {
      form
        .validateFields()
        .then(values => {
          onCreate(values);
        })
        .catch(info => {
          console.log('Validate Failed:', info);
        });
    }}
  >
    <Form
      layout="inline"
      form={form}
      name="consultant_basic"
      className="gx-signin-form gx-form-row0">
       {showSelectSubmission && submissions && <Col span={24}>
            <Form.Item label="Submission" name={"submission"}
              rules={[{required: true, message: 'Please choose a submission!'}]} >
                <Select showSearch
                  optionFilterProp="children" 
                    filterOption={(input, option) =>
                      String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {submissions.map((submission)=>(
                        <Option key={submission.id} value={submission.id}>{submission.consultant_name} :: {submission.vendor_name} :: {submission.client_name} :: {submission.job_title}</Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>}
        <Col span={24}>
            <Form.Item label="Project Type" name="project_type" rules={[{required: true, message: 'Please select a project type!'}]}>
                <Select onChange={handleProjectChange}>  
                    <Option key={'inhouse'} value={'inhouse'}>In-House</Option>
                    <Option key={'outsource'} value={'outsource'}>Outsource</Option>
                    <Option key={'regular'} value={'regular'}>Regular</Option>
                </Select>
            </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item label="Employer Company" name="employer_company" >
                <Select 
                  showSearch
                  optionFilterProp="children" 
                  filterOption={(input, option) =>
                    String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleEmployerCompanyChange}
                >  
                    {EmployerCompanyOptions.map((item)=>{
                        return <Option key={item.handle} value={item.handle}>{item.title}</Option>
                    })}
                </Select>
            </Form.Item>
        </Col>  
          { currentEmployerCompany  === 'Other' &&
            <Col span={24}>
              <Form.Item label='Name of other employer company'
                        rules={[{required: true, message: 'Please enter a employer company name!'}]} 
                        name={"other_employer_company_name"} 
                  >
                <Input type="text" autoComplete="off" />
              </Form.Item> 
            </Col>
          }
        {loggedInUser &&  loggedInUser.level <= levelsConst['Associate Team Lead'] && <Col span={24}>
        <Form.Item label='Project Rate'
                   rules={[{required: true, message: 'Please enter a valid project rate!'}]} 
                   name={"project_rate"}>
          <Input type="number" autoComplete="off"/>
        </Form.Item> 
        </Col>}

        <Col span={24}>
        <Form.Item label='Status' name="project_status" initialValue={record && record.project_status}>
                <Select
                    showSearch 
                    optionFilterProp="children" 
                      filterOption={(input, option) =>
                        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        options={offersStatusFilters?offersStatusFilters.filter((item)=>item.title !== "All").map((item) => ({
                          key: item.handle,
                          value: item.handle,
                          label: item.title,
                        })):null}
                >
                </Select>
                </Form.Item>
        </Col>
      <Col span={24}>
      <Form.Item label='Date of Offer' name="offered_date"   rules={[{required: true, message: 'Please select a offer received date!'}]}  >
        <DatePicker
          format="MM/DD/YYYY"
          style={{ width: '100%' }}
          disabled={(record && (record.project_status === 'Completed' || record.project_status === 'Ongoing')) || (record && record.project_status !== 'Pending')}  
        />
      </Form.Item>
      </Col>

      <Col span={24}>
      <Form.Item label='Project Start Date' name="project_start_date"   
        // rules={[{required: true, message: 'Please enter a valid project start date!'}]}
       >
        <DatePicker
          format="MM/DD/YYYY"
          style={{ width: '100%' }}
          disabled={record && (record.project_status === 'Completed' || record.project_status === 'Ongoing')}
        />
      </Form.Item>
      </Col>

      <Col span={24}>
      <Form.Item label='Project End Date' name="project_end_date"
        // rules={[{required: true, message: 'Please enter a valid project end date!'}]}
       >
        <DatePicker
          format="MM/DD/YYYY"
          style={{ width: '100%' }}
          disabled={record && record.project_status === 'Completed'}
        />
      </Form.Item>
      </Col>
        
       {currentProject == 'inhouse'? <Col span={24}>
       <Input.Group>
            <Form.Item label="Inhouse engineer" name="inhouse_engineer"  rules={[{required: true, message: 'Please add a support id!'}]}>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select Inhouse engineer"
                      options={supportIdList?supportIdList.map((item) => ({
                      key: item.user_id,
                      value: item.user_id,
                      label: item.name,
                    })):null}
                    optionFilterProp="label" 
                      filterOption={(input, option) =>
                        String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                    </Select>
            </Form.Item>
              </Input.Group>
        </Col>:<null/>}
        {loggedInUser &&  loggedInUser.level <= levelsConst['Team Lead'] && <Col span={24}>
        <Input.Group>
             <Form.Item label="Project Coordinator" name="project_coordinator"  
            //  rules={[{required: true, message: 'Please add a Project Coordinator!'}]}
             >
                     <Select
                       showSearch
                       allowClear
                       placeholder="Select Project Coordinator"
                       options={projectCoordinatorsNoPaginationList?projectCoordinatorsNoPaginationList.map((item) => ({
                       key: item.user_id,
                       value: item.user_id,
                       label: item.name,
                     })):null}
                     optionFilterProp="label" 
                       filterOption={(input, option) =>
                         String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                     >
                     </Select>
             </Form.Item>
               </Input.Group>
         </Col>}
        {loggedInUser &&  loggedInUser.level <= levelsConst['Floor Manager'] && <Col span={24}>
        <Form.Item label='Payroll Cost' 
                   name={"payroll_cost"}>
          <Input type="number" autoComplete="off" />
        </Form.Item> 
        </Col>}
        {loggedInUser &&  loggedInUser.level <= levelsConst['Floor Manager'] &&
         <Col span={24}>
        <Form.Item label='Support Rate'
                   name={"support_rate"}>
          <Input type="number" autoComplete="off" />
        </Form.Item> 
        </Col>}
        {user && user.groups && user.groups.includes(groups['Admin']) && 
        <Col span={24}>
        <Form.Item label='Device handling rate'
                   name={"device_handling_rate"}>
          <Input type="number" autoComplete="off" />
        </Form.Item> 
        </Col>} 
        {loggedInUser &&  loggedInUser.level <= levelsConst['Floor Manager'] && 
        <Col span={24}>
        <Form.Item label='Consultant Rate'
                   name={"consultant_rate"}>
          <Input type="number" autoComplete="off" />
        </Form.Item> 
        </Col>}
        {loggedInUser &&  loggedInUser.level <= levelsConst['Floor Manager'] &&<Col span={24}>
        <Form.Item label='Payroll Tax Type' name={"payroll_tax_type"} >
              <Select>
                <Option key={'w2'} value={'w2'}>W2</Option>
                <Option key={'1099-b'} value={'1099-b'}>1099-Business</Option>
                <Option key={'1099-i'} value={'1099-i'}>1099-Individual</Option> 
              </Select>
            </Form.Item>    
        </Col>}
         <Col span={24}>
            <Form.Item label='Tax Term' name={"tax_term"} >
              <Select>
                <Option key={'w2'} value={'w2'}>W2</Option>
                <Option key={'c2c'} value={'c2c'}>C2C</Option>
                <Option key={'1099'} value={'1099'}>1099</Option>
              </Select>
            </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item label="Remarks" name="remark">
                <TextArea rows={2} placeholder="Project Remarks" autoComplete="off"/>
            </Form.Item>
        </Col>

      <Col span={24}>
      <Form.Item label='Technologies'
                name={"technologies"}>
        <TextArea rows={2} placeholder="Python, C++, AWS" autoComplete="off"/>
      </Form.Item>
      </Col>

      {title === "Approve Offer" && 
        <Col span={24}>
        <Form.Item label='Project start date acknowledged'
                  name={"project_start_date_acknowledged"}>
          <Checkbox rows={2} onChange={handleCheckBox} />
        </Form.Item>
        </Col>
      }

    </Form>
  </Modal>
  )
}

export default EditOfferForm