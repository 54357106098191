import {
  DELETE_CONTACT_SUCCESS,
  GET_ALL_CLIENT_SUCCESS,
  GET_ALL_CONTACT_SUCCESS,
  GET_ALL_CONTACT_WITH_SAMW_NAME_SUCCESS,
  GET_ALL_PARTNER_SUCCESS,
  GET_ALL_VENDOR_SUCCESS,
  GET_POC_LIST_SUCCESS,
  ON_ADD_CONTACT_SUCCESS,
  ON_ADD_POC_SUCCESS,
  UPDATE_CONTACT_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  contact: null,
  totalContacts: 0,
  existingConsultantCountWithSameName: null,
  contacts: null,
  clients: null,
  vendors: null,
  vendorsList: [],
  totalVendors: 0,
  partners: null,
  pocList: null,
  totalPoc:0,
  selectedContact: []
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case GET_ALL_CONTACT_SUCCESS: {
      return {
        ...state,
        contacts: action.payload.results,
        totalContacts: action.payload.count,
      }
    }

    case GET_ALL_CONTACT_WITH_SAMW_NAME_SUCCESS: {
      return {
        ...state,
        existingConsultantCountWithSameName: action.payload.results,
      }
    }

    case GET_ALL_CLIENT_SUCCESS: {
      return {
        ...state,
        clients: action.payload.results,
        totalContacts: action.payload.count,
      }
    }
    case GET_POC_LIST_SUCCESS: {
      return {
        ...state,
        pocList: action.payload.results,
        totalPoc: action.payload.count,
      }
    }
    case GET_ALL_VENDOR_SUCCESS: {
      return {
        ...state,
        vendors: action.payload.results,
        vendorsList:action.payload.results,
        totalVendors: action.payload.count,
      }
      // return {
      //   ...state,
      //   vendors: [...state.vendors, ...action.payload.results[0].filter(newVendor => !state.vendors.some(existingVendor => existingVendor.id === newVendor.id))],//unique objects in vendors
      //   vendorsList:  [...state.vendorsList, ...action.payload.results[0].filter(newVendor => !state.vendors.some(existingVendor => existingVendor.id === newVendor.id))],
      //   totalVendors: action.payload.count, 
      // }
    }
    case GET_ALL_PARTNER_SUCCESS: {
      return {
        ...state,
        partners: action.payload.results,
        totalContacts: action.payload.count,
      }
    }

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map((contact) => contact.id === action.payload.id ? action.payload : contact),
      };

    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.filter((contact) => contact.id !== action.payload.id),
      };

    case ON_ADD_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload,
      };
      case ON_ADD_POC_SUCCESS :{
        return {
          ...state,
          pocList: [action.payload[0], ...state.pocList],
          totalPoc: state.totalPoc+1,
        }
      }

    default:
      return state;
  }
}
