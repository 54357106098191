import IntlMessages from "../../util/IntlMessages";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux";
import {
  fetchAllConsultants,
  fetchAllMarketerAnalysis,
  fetchAssessmentsList,
  fetchAssignedConsultantByGroupTableData,
  fetchAssignedConsultantsTableData,
  fetchBarData,
  fetchClients,
  fetchConsultants,
  fetchContacts,
  fetchDonutData,
  fetchEmployees,
  fetchFullYearOffers,
  fetchHiredData,
  fetchMarketerStandupList,
  fetchMarketingProfileList,
  fetchMonthlyOffers,
  fetchMonthwiseSubmissionsAndAssessmentsData,
  fetchOffers,
  fetchOngoingOffers,
  fetchPieData,
  fetchPnlOffers,
  fetchPocList,
  fetchProejctTypeByPnlTableData,
  fetchProjectCoordinatorsOffersList,
  fetchRatioData,
  fetchRecentRecruits,
  fetchRecruitersConsultantCount,
  fetchRecruitsByStatus,
  fetchScreenings,
  fetchStatusMarketingCount,
  fetchSubmissions,
  fetchSupportPersonsList,
  fetchTasks,
  fetchTotalMarketingProfileTableData
} from "../../appRedux/actions";

import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
import { FilePptOutlined } from "@ant-design/icons";
import moment from "moment";
import { groups, levelsConst } from "../../constants/Constants";
import { DeleteKeysFromSession } from "../../routes/recruitment/screening/utils";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const activeMenuItemStyle = {
  color: '#ff9d14',
};

const AdminSidebar = ({navStyleClass, defaultOpenKeys, selectedKeys, themeType, user}) => {
  const currentDate = new Date();
  const defaultStartDate = moment(`${currentDate.getFullYear()}-${currentDate.getMonth()+1}-01`);
  const defaultEndDate = moment();  
  const defaultMonthEndDate = moment().endOf('month'); 
  const dispatch = useDispatch();

  const keysToDeleteFromSession = ['pageSize','employeePageNo','profilePageNo','submissionPageNo',
  'assessmentPageNo','clientPagination','pocPageNo','ipPageNo','transferRequestPageNo','offersPageNo',
  'ongoingOffersPageNo','paginationInEmployeeConsultant','supportPersonsoffersPageNo']  // this keys from the session will be deleted in the click of the side bar tabs in the menu

  const fetchDashboardData = () => {
    const storedDataString = sessionStorage.getItem('pagination');
    const storedData = storedDataString ? JSON.parse(storedDataString) : {};
    delete storedData.paginationPage;
    delete storedData.openTab; 
    DeleteKeysFromSession(keysToDeleteFromSession)
  
    sessionStorage.setItem('pagination', JSON.stringify(storedData));
    dispatch(fetchTasks(user));
    dispatch(fetchHiredData());
    dispatch(fetchRatioData());
    dispatch(fetchPieData());
    dispatch(fetchDonutData());
    dispatch(fetchRecentRecruits());
    dispatch(fetchBarData());
    dispatch(fetchRecruitsByStatus());
    dispatch(fetchMonthwiseSubmissionsAndAssessmentsData())
    dispatch(fetchFullYearOffers({year:moment(defaultStartDate).format('YYYY') ,include_self:true}));
    dispatch(fetchOngoingOffers({year:moment(defaultStartDate).format('YYYY')}));
    dispatch(fetchStatusMarketingCount());

    if(user && user.groups.includes('Admin')){
        dispatch(fetchProejctTypeByPnlTableData())
        dispatch(fetchTotalMarketingProfileTableData())
      }
    if(user && (user.groups.includes('Admin') || user.groups.includes(groups['Marketing']) && user.level <= levelsConst['Associate Team Lead'])){
      // dispatch(fetchAssignedConsultantByGroupTableData())
      dispatch(fetchMonthlyOffers({from_date:moment(defaultStartDate).format('YYYY-MM-DD') ,
      to_date: moment(defaultMonthEndDate).format('YYYY-MM-DD') ,include_self:true}))

    }
    if(user && (user.groups.includes('Admin') || user.groups.includes(groups['Marketing']) ) ){
        dispatch(fetchAssignedConsultantsTableData(
        moment(defaultStartDate).format('YYYY-MM-DD'),moment(defaultEndDate).format('YYYY-MM-DD')));
    }

      if(user && user.level <= levelsConst['Associate Team Lead'] ){
        dispatch(fetchAllMarketerAnalysis(
         moment(defaultStartDate).format('YYYY-MM-DD'),
         moment(defaultEndDate).format('YYYY-MM-DD')
          ));
      }
      if(user && user.groups.includes('Admin')){
        dispatch(fetchPnlOffers(
          // moment(defaultStartDate).format('YYYY-MM-DD'),
          // moment(defaultEndDate).format('YYYY-MM-DD')
          ))  
        }
          dispatch(fetchRecruitersConsultantCount(
            moment(defaultStartDate).format('YYYY-MM-DD'),
            moment(defaultEndDate).format('YYYY-MM-DD')
            // we can send a status also in the thired parameter here
          ));
  };

  const fetchMarketerDashboardData = ()=>{
    dispatch(fetchFullYearOffers());
    dispatch(fetchAllMarketerAnalysis());
    dispatch(fetchHiredData());
  }

  const fetchConsultantList = () => {
    const storedDataString = sessionStorage.getItem('pagination');
    const storedData = storedDataString ? JSON.parse(storedDataString) : {};
  
    delete storedData.paginationPage;
    delete storedData.filterData;
    delete storedData.openTab; 
    storedData.resetPagination = 1;
    DeleteKeysFromSession(keysToDeleteFromSession)
    sessionStorage.setItem('pagination', JSON.stringify(storedData));
    dispatch(fetchAllConsultants());
  };
  const fetchProfileList = () => {
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchMarketingProfileList())
  }
  

  // const fetchConsultantList = () => {
  //   sessionStorage.removeItem('paginationPage');
  //   sessionStorage.setItem('resetPagination' , 1);
  //   dispatch(fetchAllConsultants());
  //   // sessionStorage.removeItem('paginationPage')
  // };
  const fetchScreeningData = () => {
    dispatch(fetchScreenings());
  };

  const fetchEmployeeList = () => {
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchEmployees());
  };

  const fetchRecruiterList = () => {
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchEmployees(1, null, 'recruitment'));
  };

  const fetchMarketerList = () => {
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchEmployees(1, null, 'marketing'));
  };

  const fetchContactData = (contactType) => {
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchContacts({type:contactType}))

  };

  const fetchPocData = ()=>{
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchPocList());
  }

  const fetchMarketerStandup = ()=>{
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchMarketerStandupList())
  }


  const fetchingConsultantsData = () => {
    const storedDataString = sessionStorage.getItem('pagination');
    const storedData = storedDataString ? JSON.parse(storedDataString) : {};
  
    delete storedData.paginationPage;
    delete storedData.filterData;
    delete storedData.openTab;  
    storedData.resetPagination = 1;

    sessionStorage.setItem('pagination', JSON.stringify(storedData));
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchConsultants(null, false, {page: 1, marketing_consultants: true}))
  }

  const fetchOngoingOffersList = ()=>{
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchOffers({page: 1,project_status:"o"}))
  }
  const fetchOffersList = ()=>{
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchOffers())
  }
  const fetchSubmissionList = ()=>{
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchSubmissions())
  }
  const fetchAssessment = ()=>{
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchAssessmentsList())
  }

  const fetchProjectCoordinatorDashboardData = () => {
    dispatch(fetchProjectCoordinatorsOffersList({status:'j'}))
    dispatch(fetchProjectCoordinatorsOffersList({status:'o'}))
    dispatch(fetchSupportPersonsList({pagination:false}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchSupportPerson = () => {
    dispatch(fetchSupportPersonsList({pagination:true}))
    DeleteKeysFromSession(keysToDeleteFromSession)

  }

  const fetchProjectCoordinators = () => {
    dispatch(fetchEmployees(1 ,null ,'project_coordination'))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchEngineersList = () => {
    dispatch(fetchEmployees(1 ,null ,'engineering'))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }
  // const fetchingConsultantsData = () => {
  //   sessionStorage.removeItem('paginationPage');
  //   sessionStorage.setItem('resetPagination' , 1);
  //   dispatch(fetchConsultants())
  // }

  const mathch = selectedKeys.split('/').length

  const [activeKey ,setActiveKey ] = useState();
  const [groupActiveKey ,setGroupActiveKey] = useState();
  useEffect(()=>{
     setGroupActiveKey(selectedKeys.split('/')[1])
    if(mathch < 3){
      setActiveKey(selectedKeys.split('/')[mathch-1])
      return
    }if(mathch <= 4){
      setActiveKey(selectedKeys.split('/')[mathch-2])
      return
    }    
   else{
    setActiveKey(selectedKeys.split('/')[mathch-3])
    }
  },[mathch,selectedKeys])

  return (
    <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]}
          theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">
      {/* <MenuItemGroup key="dashboard" className="gx-menu-group">
        <SubMenu key="dashboard" popupClassName={navStyleClass} 
                 title={<span> <i className="icon icon-dasbhoard"/>
                    <span><IntlMessages id="sidebar.dashboard"/></span></span>}> */}
          <Menu.Item key="dashboard.recruitment">
            <Link to={"/username/dashboard".replace('username', user.username)}
                  onClick={()=>fetchDashboardData()}>
              <span style={activeKey === 'dashboard' ? activeMenuItemStyle : null}>
              <i className="icon icon-auth-screen"/><IntlMessages id="Dashboard"/></span>
              {/* <span><IntlMessages id="sidebar.recruitment"/></span> */}
              </Link>
          </Menu.Item>
          {/* <Menu.Item key="dashboard.marketing">
            <Link to={"/username/dashboard/marketing".replace('username', user.username)}
                  onClick={()=>fetchMarketerDashboardData()}
                  ><i className="icon icon-team"/>
              <span><IntlMessages id="sidebar.marketing"/></span></Link>
          </Menu.Item>
        </SubMenu>
      </MenuItemGroup> */}

      {/* <MenuItemGroup key="recruitment" className="gx-menu-group"> */}
        {/* <SubMenu key="recruitment" popupClassName={navStyleClass} 
                 title={<span> <i className="icon icon-auth-screen"/>
                    <span><IntlMessages id="sidebar.recruitment"/></span></span>}> */}
                    <Menu.Item style={{color:"white"}}>Recruitment</Menu.Item>
          <Menu.Item key="recruitment.recruiters">
            <Link to={"/".concat(user.username.concat("/recruitment/recruiter/list"))}
                  onClick={() => fetchRecruiterList()}>
              <span style={groupActiveKey === 'recruitment' && activeKey ===  'recruiter'  ? activeMenuItemStyle : null}><i className="icon icon-family"/><IntlMessages id="sidebar.recruiters"/></span></Link>
          </Menu.Item>
          {/* <Menu.Item key="recruitment.standup"> 
            <Link to={"/".concat(user.username.concat("/recruitment/standup"))}
                  onClick={() => fetchRecruiterList()}>
              <span style={groupActiveKey === 'recruitment' && activeKey ===  'standup'  ? activeMenuItemStyle : null}><i className="icon icon-family"/><IntlMessages id="sidebar.standup"/></span></Link>
          </Menu.Item> */}
          <Menu.Item key="recruitment.consultants">
            <Link to={"/".concat(user.username.concat("/recruitment/consultant/list"))}
                  onClick={() => fetchConsultantList()}>
              <span style={groupActiveKey === 'recruitment' && activeKey === 'consultant' ? activeMenuItemStyle : null}>
              <i className="icon icon-profile"/>
                <IntlMessages id="sidebar.allconsultants"/>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="recruitment.transferRequests">
            <Link to={"/".concat(user.username.concat("/transfer-requests"))}
                  onClick={() => fetchConsultantList()}>
              <span style={groupActiveKey === 'transfer-requests' && activeKey === 'transfer-requests' ? activeMenuItemStyle : null}>
                <i className="icon icon-reply"/>
                <IntlMessages id="sidebar.transferRequests"/>
              </span>
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="recruitment.screening">
            <Link to={"/".concat(user.username.concat("/recruitment/screening/list"))}
                  onClick={() =>fetchScreeningData()}><i className="icon icon-hotel-booking"/>
              <span> <IntlMessages id="sidebar.screening"/></span></Link>
          </Menu.Item> */}
        {/* </SubMenu> */}
      {/* </MenuItemGroup> */}


      {/* <MenuItemGroup key="marketing" className="gx-menu-group"> */}
        {/* <SubMenu key="marketing" popupClassName={navStyleClass}
                 title={<span> <i className="icon icon-team"/>
                    <span><IntlMessages id="sidebar.marketing"/></span></span>}> */}
                    <Menu.Item style={{color:"white"}}>Marketing</Menu.Item>
          <Menu.Item key="marketing.marketers">
            <Link to={"/".concat(user.username.concat("/marketing/marketer/list"))}
                  onClick={() => fetchMarketerList()}>
              <span style={groupActiveKey === 'marketing' && activeKey === 'marketer' ? activeMenuItemStyle : null}><i className="icon icon-family"/><IntlMessages id="sidebar.marketers"/></span></Link>
          </Menu.Item>
          <Menu.Item key="marketing.standup"> 
            <Link to={"/".concat(user.username.concat("/marketing/standup"))}
                  onClick={() => fetchMarketerStandup()}>
              <span style={groupActiveKey === 'marketing' && activeKey ===  'standup'  ? activeMenuItemStyle : null}><i className="icon icon-family"/><IntlMessages id="sidebar.standup"/></span></Link>
          </Menu.Item>
          <Menu.Item key="marketing.consultants">
                        <Link to={"/".concat(user.username.concat("/marketing/consultant/list"))} onClick={() => fetchingConsultantsData()}>
                          <span style={groupActiveKey === 'marketing' && activeKey === 'consultant' ? activeMenuItemStyle : null}>
                          <i className="icon icon-avatar"/>
                          <IntlMessages id="sidebar.consultants"/></span></Link>
           </Menu.Item>
           <Menu.Item key="marketing.ongoingoffers">
                        <Link to={"/".concat(user.username.concat("/marketing/ongoingoffers"))} onClick={() => fetchOngoingOffersList()}>
                          <span style={groupActiveKey === 'marketing' && activeKey === 'ongoingoffers' ? activeMenuItemStyle : null}> 
                          <i className="icon icon-card"/>
                          <IntlMessages id="sidebar.ongoingoffers"/></span></Link>
           </Menu.Item>
          <Menu.Item key="marketing.profiles">
                        <Link to={"/".concat(user.username.concat("/marketing/profiles/list"))} 
                        onClick={() => fetchProfileList()}>
                         <span style={groupActiveKey === 'marketing' && activeKey === 'profiles' ? activeMenuItemStyle : null}> 
                         <i className="icon icon-card"/>
                          <IntlMessages id="sidebar.profiles"/></span></Link>
                      </Menu.Item>
          <Menu.Item key="marketing.submissions">
                        <Link to={"/".concat(user.username.concat("/marketing/submission/list"))} onClick={() => fetchSubmissionList()}>
                         <span style={groupActiveKey === 'marketing' && activeKey === 'submission' ? activeMenuItemStyle : null}> 
                         <i className="icon icon-card"/>
                          <IntlMessages id="sidebar.submissions"/></span></Link>
          </Menu.Item>

          <Menu.Item key="marketing.assessments">
                        <Link to={"/".concat(user.username.concat("/marketing/assessment/list"))} onClick={() => fetchAssessment()}>
                         <span style={groupActiveKey === 'marketing' && activeKey === 'assessment' ? activeMenuItemStyle : null}> 
                          {/* <FilePptOutlined  style={{fontSize:'20px'}}/>  */}
                          <i className="icon icon-card"/><IntlMessages id="sidebar.assessments"/></span></Link>
                      </Menu.Item>
          <Menu.Item key="marketing.offers">
                        <Link to={"/".concat(user.username.concat("/marketing/offer/list"))} onClick={() => fetchOffersList()}>
                          <span style={groupActiveKey === 'marketing' && activeKey === 'offer' ? activeMenuItemStyle : null}> 
                          <i className="icon icon-card"/>
                          <IntlMessages id="sidebar.offers"/></span></Link>
                      </Menu.Item>
                        <Menu.Item key="contacts.clients">
                          <Link to={"/".concat(user.username.concat("/marketing/client/list"))}  onClick={() => fetchContactData('c')}>
                           <span style={activeKey === 'client' ? activeMenuItemStyle : null}><i className="icon icon-company"/> <IntlMessages id="sidebar.clients"/></span></Link>
                        </Menu.Item>
                      <Menu.Item key="contacts.vendors">
                          <Link to={"/".concat(user.username.concat("/marketing/vendor/list"))} onClick={() => fetchContactData('v')}>
                            <span style={activeKey === 'vendor' ? activeMenuItemStyle : null}><i className="icon icon-contacts"/><IntlMessages id="sidebar.vendors"/></span></Link>
                      </Menu.Item>

                        <Menu.Item key="contacts.poc">
                          <Link to={"/".concat(user.username.concat("/marketing/poc/list"))} onClick={() => fetchPocData()}>
                            <span  style={activeKey === 'poc' ? activeMenuItemStyle : null}><i className="icon icon-all-contacts"/><IntlMessages id="sidebar.poc"/></span></Link>
                        </Menu.Item>
         

          {/* <Menu.Item key="marketing.submissions">
           <Link to={"/".concat(user.username.concat("/recruitment/screening/list"))}
              onClick={() =>fetchScreeningData()}><i className="icon icon-hotel-booking"/>
             <span> <IntlMessages id="sidebar.screening"/></span></Link>
          </Menu.Item> */}
          {/* <Menu.Item key="marketing.projects">
               <Link to={"/".concat(user.username.concat("/marketing/project/list"))}><i className="icon icon-modal"/>
                 <span> <IntlMessages id="sidebar.projects"/></span></Link>
          </Menu.Item> */}
        {/* </SubMenu> */}
      {/* </MenuItemGroup> */}


      {/* <MenuItemGroup key="employee" className="gx-menu-group"> */}
        {/* <SubMenu key="employee" popupClassName={navStyleClass}
                 title={<span> <i className="icon icon-social"/>
                    <span><IntlMessages id="sidebar.employees"/></span></span>}> */}
                    <Menu.Item style={{color:"white"}}>Employee</Menu.Item>
          <Menu.Item key="employee.marketers">
            <Link to={"/".concat(user.username.concat("/marketing/marketer/list"))}
                  onClick={() => fetchMarketerList()}>
              <span style={groupActiveKey === 'employee' && activeKey === 'marketer' ? activeMenuItemStyle : null }>
              <i className="icon icon-profile"/>
                <IntlMessages id="sidebar.marketers"/></span></Link>
          </Menu.Item>
          <Menu.Item key="employee.recruiters">
            <Link to={"/".concat(user.username.concat("/recruitment/recruiter/list"))}
                  onClick={() => fetchRecruiterList()}>
              <span style={groupActiveKey === 'employee' && activeKey === 'recruiter' ? activeMenuItemStyle : null}>
                <i className="icon icon-profile"/><IntlMessages id="sidebar.recruiters"/></span></Link>
          </Menu.Item>
          <Menu.Item key="project_coordination.projectcoordinators">
            <Link to={"/".concat(user.username.concat("/project_coordination/project_coordinators"))} 
              onClick={()=>fetchProjectCoordinators()}
              > <i className="icon icon-card"/>
              <span style={{ display: 'inline-block', lineHeight: 1, marginBottom: '0px', transform: 'translate(0px, 8px)', top: '10px'}} >
                Project <br></br> Coordinator</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="employee.projectcoordinators">
            <Link to={"/".concat(user.username.concat("/engineering/engineers/list"))} 
              onClick={()=>fetchEngineersList()}
              > <i className="icon icon-card"/>
              <IntlMessages id='sidebar.listType.engineers' />
            </Link>
          </Menu.Item>
          <Menu.Item key="employee.all">
            <Link to={"/".concat(user.username.concat("/employee/list"))}
                  onClick={() =>fetchEmployeeList()}>
              <span style={groupActiveKey === 'employee' && activeKey === 'employee' ? activeMenuItemStyle : null}>
                  <i className="icon icon-map-street-view"/>
                <IntlMessages id="sidebar.employees"/></span></Link>
          </Menu.Item>
        {/* </SubMenu> */}
      {/* </MenuItemGroup> */}
      <Menu.Item style={{color:"white"}}>Project coordinator</Menu.Item>
          {/* <Menu.Item key="engineer.dashboard">
            <Link to={"/".concat(user.username.concat("/project_coordination/dashboard"))} onClick={()=>fetchProjectCoordinatorDashboardData()}> 
              <i className="icon icon-card"/><span>Dashboard</span></Link>
            </Menu.Item> */}
            <Menu.Item key="engineer.support_person">
            <Link to={"/".concat(user.username.concat("/project_coordination/support_person"))} onClick={()=>fetchSupportPerson()}> 
              <i className="icon icon-card"/><span>Support Person</span></Link>
          </Menu.Item>

      {/* <MenuItemGroup key="marketing.contacts" className="gx-menu-group"> */}
                        {/* <SubMenu key="contacts" popupClassName={navStyleClass}
                               title={<span> <i className="icon icon-queries"/>
                                  <span><IntlMessages id="sidebar.contacts"/></span></span>}> */}
                      <Menu.Item style={{color:"white"}}>Contacts</Menu.Item>

                        <Menu.Item key="contacts.ip">
                          <Link to={"/".concat(user.username.concat("/marketing/partner/list"))}  onClick={() => fetchContactData('i')}>
                           <span style={activeKey === 'partner' ? activeMenuItemStyle : null}> <i className="icon icon-diamond"/><IntlMessages id="sidebar.ip"/></span></Link>
                        </Menu.Item>

                        <Menu.Item key="contacts.remove">
                          <Link to={"/".concat(user.username.concat("/marketing/mergeClient"))}  onClick={() => dispatch(fetchClients())}>
                           <div style={activeKey === 'remove-duplicate' ? activeMenuItemStyle : null}> <i className="icon icon-diamond"/> <IntlMessages id="sidebar.remove.duplicates"/></div></Link>
                        </Menu.Item>

                        <Menu.Item key="contacts.all">
                          <Link to={"/".concat(user.username.concat("/marketing/contact/list"))}  onClick={() => fetchContactData(null)}>
                            <span style={activeKey === 'contact' ? activeMenuItemStyle : null}><i className="icon icon-all-contacts"/><IntlMessages id="sidebar.contacts.all"/></span></Link>
                        </Menu.Item>

                      {/* </SubMenu> */}

            {/* </MenuItemGroup> */}
    </Menu>
  );
};
export default AdminSidebar;
